// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { NavLink, Link } from "react-router-dom";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MPBox from "components/MPBox";
import MPTypography from "components/MPTypography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import MPButton from "components/MPButton/index";

function SvcFooter({ company, links }) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <MPBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MPTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </MPTypography>
        </Link>
      </MPBox>
    ));

  return (
    <MPBox py={2} width="100vw" bgColor="#f4f4f4">
      <MPBox margin={"0 auto"} maxWidth="1200px">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} xl={4}>
            <MPBox px={3}>
              <MPBox>
                <MPTypography variant="body2" fontWeight="bold" color="black">
                  고객센터
                </MPTypography>
              </MPBox>
              <MPBox height="14px">
                <MPTypography component="span" variant="caption" fontWeight="regular">
                  이메일 : prden@dybsoft.com
                </MPTypography>
              </MPBox>
              <MPBox>
                <MPTypography component="span" variant="caption" fontWeight="regular">
                  전화 : 010-2694-1810 (시간: 08:00 ~ 23:30, 연중무휴)
                </MPTypography>
                <MPBox>
                  <MPButton
                    component="a"
                    href={"tel:010-2694-1810"}
                    to={"/"}
                    variant="outlined"
                    color="black"
                    size="small"
                  >
                    전화하기
                  </MPButton>
                </MPBox>
              </MPBox>
            </MPBox>
          </Grid>
          <Grid item xs={12} md={6} xl={4} mt={{ xs: "13px", lg: 0 }}>
            <MPBox px={3}>
              <MPBox>
                <MPTypography variant="body2" fontWeight="bold" color="black">
                  사용안내
                </MPTypography>
              </MPBox>
              <MPBox mt={1} mb={0.5}>
                <MPButton component={Link} to={"/"} variant="outlined" color="black" size="small">
                  개인정보 처리 방침
                </MPButton>
              </MPBox>
              <MPBox display="flex" justifyContent="flex-start">
                <MPBox mr={1}>
                  <MPButton component={Link} to={"/"} variant="outlined" color="black" size="small">
                    이용 약관
                  </MPButton>
                </MPBox>
                <MPBox>
                  <MPButton
                    component={Link}
                    to={"/inquiry"}
                    variant="outlined"
                    color="black"
                    size="small"
                  >
                    Contact us.
                  </MPButton>
                </MPBox>
              </MPBox>
            </MPBox>
          </Grid>
          <Grid item xs={12} xl={4}>
            <MPBox px={3}>
              <MPBox>
                <MPTypography variant="body2" fontWeight="bold" color="black">
                  (주)프라버던스
                </MPTypography>
              </MPBox>
              <MPBox height="14px">
                <MPTypography component="span" variant="caption" fontWeight="regular">
                  서울특별시 송파구 잠실동 백제고분로 12길 8-10 3층
                </MPTypography>
              </MPBox>
              <MPBox height="14px">
                <MPTypography component="span" variant="caption" fontWeight="regular">
                  대표자:김성준
                </MPTypography>
              </MPBox>
              <MPBox height="14px">
                <MPTypography component="span" variant="caption" fontWeight="regular">
                  사업자등록번호:613-86-03243
                </MPTypography>
              </MPBox>
            </MPBox>
            <MPBox fontSize={size.sm} pt={2} px={0.5}>
              &copy; {new Date().getFullYear()}, made by
              <Link to={href} target="_blank">
                <MPTypography variant="button" fontWeight="medium">
                  &nbsp;{name}&nbsp;
                </MPTypography>
              </Link>
              All Rights Reserved.
            </MPBox>
          </Grid>
        </Grid>
      </MPBox>
    </MPBox>
  );
}

// Setting default values for the props of SvcFooter
SvcFooter.defaultProps = {
  company: { href: "https://www.dybsoft.com/", name: "Providence Inc." },
  links: [
    { href: "https://www.dybsoft.com/", name: "Home" },
    { href: "https://github.com/dybsoft", name: "DYB Soft Github" },
    { href: "https://prde.tistory.com/", name: "Blog" },
  ],
};

// Typechecking props for the Footer
SvcFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default SvcFooter;
