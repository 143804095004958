/*
	SPDX-FileCopyrightText: © 2023 DYB Soft Corporation. <dybsoft1118@naver.com>
	SPDX-License-Identifier: BSD-3-Clause
*/

// ====================== React Library ===========================
import { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import dybApi from "lib/api/dybApi";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import { filterProject, searchKeyword } from "../../modules/projectFilter";

// ====================== MP&MUI Components  ======================
import MPButton from "components/MPButton/index";
import MPBox from "components/MPBox/index";
import MPTypography from "components/MPTypography/index";
import MPAvatar from "components/MPAvatar";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import { Button, CardActions, CardContent, Grid } from "../../../node_modules/@mui/material/index";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

// ====================== Service Components ======================
import HomeLayout from "services/LayoutContainers/HomeLayout";
import HomeNavbar from "services/Navbars/HomeNavbar";
import SvcFooter from "services/Footer/SvcFooter";

// ====================== Images ==================================

import flutterImg from "assets/images/company/flutter-app.png";
import frontendImg from "assets/images/company/front-end.png";
import backendImg from "assets/images/company/back-end.png";
import consulting from "assets/images/company/consulting.png";
import serverSetting from "assets/images/company/server-setting.png";
import companyLogos from "assets/images/company/company-logos.png";

import contactUs from "assets/images/company/contact-us.png";
import providenceMap from "assets/images/company/providence-map.png";
import serverConfiguration from "assets/images/home/server-configuration.jpg";
import ApacheJmeter from "assets/images/home/Apache-Jmeter.jpg";
import apiGateway from "assets/images/home/APIGateway.jpg";

let page = 1;
function CompanyInfo() {
  // 동적 글자 표기
  let count = 0;
  const [applyTitle, setApplyTitle] = useState("");
  const completionWord = "아래 개발 및 운영 프로젝트를 확인해 보세요";
  const dispatchRedux = useDispatch();

  const [dataList, setDataList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const goToDifferentReason = () => {
    const access = document.getElementById("different-reason");
    access.scrollIntoView({ behavior: "smooth" }, true);
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <HomeLayout>
      <HomeNavbar light={false} />
      <MPBox bgColor="#FEFEF5" height="100%" pb={3}>
        <MPBox maxWidth="1080px" m={"0 auto"}>
          <MPBox pt={3} pb={1} lineHeight={1} pl={{ xs: 2, lg: 1 }} textAlign="center">
            <MPTypography color="black" variant="h4">
              DYBSoft 소프트웨어 개발 기술
            </MPTypography>
          </MPBox>
          <MPBox pt={1} lineHeight={1} px={{ xs: 1, lg: 1 }} textAlign="center">
            <MPTypography color="black" variant="h6" fontWeight="regular">
              웹, 모바일 웹, 앱 개발 및 배치서버, API 서버 구축
            </MPTypography>
          </MPBox>
          <MPBox mt={{ xs: 1, lg: 4 }} px={{ xs: 2, lg: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative" }}>
                <MPBox mt={{ xs: 2, lg: 3 }}>
                  <MPBox display="flex" justifyContent="center">
                    <CardMedia
                      src={frontendImg}
                      component="img"
                      title="웹 프론트 기술"
                      sx={{
                        maxWidth: "100%",
                        margin: 0,
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </MPBox>
                  <MPBox mt={{ xs: 0, lg: 3 }} textAlign="center">
                    <MPTypography color="info" variant="h6">
                      01
                    </MPTypography>
                    <MPTypography color="black" variant="h5">
                      웹 프론트 기술
                    </MPTypography>
                    <MPTypography mt={2} color="black" variant="body2" fontWeight="regular">
                      Vue.js(version : Vue3, Vue2) & Nuxt.js
                    </MPTypography>
                    <MPTypography color="black" variant="body2" fontWeight="regular">
                      React.js(version : >= 16.8) & Next.js
                    </MPTypography>
                    <MPTypography color="black" variant="body2" fontWeight="regular">
                      프론트 웹 서버 : Nginx
                    </MPTypography>
                    <MPTypography color="black" variant="body2" fontWeight="regular">
                      Node 프로세스 관리 : PM2 활용
                    </MPTypography>
                  </MPBox>
                </MPBox>
              </Grid>
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative" }}>
                <MPBox mt={{ xs: 2, lg: 3 }}>
                  <MPBox display="flex" justifyContent="center">
                    <CardMedia
                      src={flutterImg}
                      component="img"
                      title="앱 (IOS/Android) 기술"
                      sx={{
                        maxWidth: "100%",
                        margin: 0,
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </MPBox>
                  <MPBox mt={{ xs: 1, lg: 3 }} textAlign="center">
                    <MPTypography color="info" variant="h6">
                      02
                    </MPTypography>
                    <MPTypography color="black" variant="h5">
                      앱 (IOS/Android) 기술
                    </MPTypography>
                    <MPTypography mt={2} color="black" variant="body2" fontWeight="regular">
                      Flutter (구글 크로스 플랫폼 앱 개발 프레임 워크)
                    </MPTypography>
                    <MPTypography color="black" variant="body2" fontWeight="regular">
                      Dart lang 하이브리드 앱
                    </MPTypography>
                  </MPBox>
                </MPBox>
              </Grid>
              <Grid item xs={12} md={6} xl={4} sx={{ position: "relative" }}>
                <MPBox mt={{ xs: 2, lg: 3 }}>
                  <MPBox display="flex" justifyContent="center">
                    <CardMedia
                      src={backendImg}
                      component="img"
                      title="백엔드 기술"
                      sx={{
                        maxWidth: "100%",
                        margin: 0,
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </MPBox>
                  <MPBox mt={{ xs: 1, lg: 4 }} textAlign="center">
                    <MPTypography color="info" variant="h6">
                      03
                    </MPTypography>
                    <MPTypography color="black" variant="h5">
                      백엔드 기술
                    </MPTypography>
                    <MPTypography mt={1} color="black" variant="body2" fontWeight="regular">
                      SpringBoot, SpringBatch, SpirngSecurity
                    </MPTypography>
                    <MPTypography mt={1} color="black" variant="body2" fontWeight="regular">
                      DB : MongoDB, MySQL, MariaDB
                    </MPTypography>
                    <MPTypography color="black" variant="body2" fontWeight="regular">
                      CI/CD : Jenkins 및 AWS Solution 활용
                    </MPTypography>
                  </MPBox>
                </MPBox>
              </Grid>
            </Grid>
          </MPBox>
        </MPBox>
      </MPBox>
      <MPBox bgColor="#FCFCFE" height="100%" pb={3}>
        <MPBox maxWidth="1080px" m={"0 auto"}>
          <MPBox pt={4} pb={1} lineHeight={1} pl={{ xs: 2, lg: 1 }} textAlign="center">
            <MPTypography color="black" variant="h4">
              AWS(Amazon Web Services) 서버 구축
            </MPTypography>
          </MPBox>
          <MPBox pt={1} lineHeight={1} px={{ xs: 2, lg: 1 }} textAlign="center">
            <MPTypography color="black" variant="body2" fontWeight="regular">
              전문 AWS solution architect가 클라우드 서버 구축을 해 드립니다.
            </MPTypography>
          </MPBox>
          <MPBox mt={{ xs: 2, lg: 4 }} px={{ xs: 2, lg: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} xl={6} sx={{ position: "relative" }}>
                <MPBox display="flex" justifyContent="center">
                  <CardMedia
                    src={consulting}
                    component="img"
                    title="컨설팅"
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
              </Grid>
              <Grid item xs={12} md={6} xl={6} sx={{ position: "relative" }}>
                <MPBox display="flex" justifyContent="center">
                  <CardMedia
                    src={serverSetting}
                    component="img"
                    title="서버구축"
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
              </Grid>
            </Grid>
            <MPBox mt={{ xs: 1, lg: 5 }} display="flex" justifyContent="center">
              <MPButton
                component={Link}
                to={"/inquiry"}
                variant="contained"
                size="medium"
                color="info"
                sx={{ fontSize: "initial" }}
              >
                바로 문의하기
              </MPButton>
            </MPBox>
          </MPBox>
        </MPBox>
      </MPBox>

      <MPBox bgColor="#FEFEF5" height="100%" pb={3}>
        <MPBox maxWidth="1080px" m={"0 auto"}>
          <MPBox pt={4} pb={1} lineHeight={1} pl={{ xs: 2, lg: 1 }} textAlign="center">
            <MPTypography color="black" variant="h4">
              프로젝트 아웃소싱
            </MPTypography>
          </MPBox>
          <MPBox pt={1} lineHeight={1} px={{ xs: 2, lg: 1 }} pb={2} textAlign="center">
            <MPTypography color="black" variant="body2" fontWeight="regular">
              대기업 및 공공기관 개발, 운영프로젝트에서 일하시고 싶으신 분
            </MPTypography>
          </MPBox>

          <MPBox px={{ xs: 2, lg: 1 }}>
            <Grid container spacing={1}>
              <Grid mt={{ xs: 0, lg: 4 }} item xs={12} md={12} xl={12}>
                <MPBox position="relative" width="100%" borderRadius="xl">
                  <CardMedia
                    src={companyLogos}
                    component="img"
                    title={"company logos"}
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      boxShadow: ({ boxShadows: { xl } }) => xl,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
              </Grid>
            </Grid>
          </MPBox>
        </MPBox>
      </MPBox>
      <MPBox bgColor="#FCFCFE" height="100%" pb={5}>
        <MPBox maxWidth="1080px" m={"0 auto"}>
          <MPBox mt={2} px={{ xs: 2, lg: 1 }}>
            <Grid container spacing={2}>
              <Grid mt={{ xs: 2, lg: 1 }} item width="100%">
                <MPBox position="relative" width="100%" borderRadius="xl">
                  <CardMedia
                    src={contactUs}
                    component="img"
                    title={"CONTACT US"}
                    sx={{
                      maxWidth: "100%",
                      margin: 0,
                      boxShadow: ({ boxShadows: { xl } }) => xl,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />

                  <MPBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: { xs: "30%", lg: "35%" },
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    mt={{ xs: 2, lg: 2 }}
                  >
                    <MPTypography color="white" variant="h4" textAlign="center">
                      CONTACT US
                    </MPTypography>
                  </MPBox>
                </MPBox>
              </Grid>
            </Grid>
          </MPBox>
          <MPBox mt={{ xs: 0, lg: -5 }} px={{ xs: 2, lg: 1 }}>
            <Grid container spacing={0} alignItems="stretch">
              <Grid mt={0} item xs={12} md={6} xl={6}>
                <MPBox position="relative" width="100%" borderRadius="xl" height="100%">
                  <CardMedia
                    src={providenceMap}
                    component="img"
                    title={"Providence Map"}
                    sx={{
                      maxWidth: "100%",
                      height: "100%", // Ensures the image fills the height of the container
                      margin: 0,
                      boxShadow: ({ boxShadows: { xl } }) => xl,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </MPBox>
              </Grid>
              <Grid mt={0} item xs={12} md={6} xl={6}>
                <MPBox
                  position="relative"
                  width="100%"
                  height="100%"
                  borderRadius="xl"
                  sx={{
                    backgroundColor: "#ffff",
                    display: "flex",
                    flexDirection: "column", // Ensures proper content stacking
                    justifyContent: "center", // Optional: Centers the content vertically
                    zinde: 3,
                  }}
                >
                  <MPBox ml={{ xs: 2, lg: 5 }}>
                    <MPBox mt={{ xs: 3, lg: 5 }}>
                      <MPTypography color="black" fontSize="1.5rem" fontWeight="bold">
                        고객센터
                      </MPTypography>
                    </MPBox>
                    <MPBox>
                      <MPTypography color="black" variant="body2" fontWeight="regular">
                        연락처 : 010-2694-1810
                      </MPTypography>
                    </MPBox>
                    <MPBox>
                      <MPTypography color="black" variant="body2" fontWeight="regular">
                        이메일 : prden@dybsoft.com
                      </MPTypography>
                    </MPBox>
                    <MPBox mt={{ xs: 3, lg: 5 }}>
                      <MPTypography color="black" fontSize="1.5rem" fontWeight="bold">
                        위치
                      </MPTypography>
                    </MPBox>
                    <MPBox>
                      <MPTypography color="black" variant="body2" fontWeight="regular">
                        서울특별시 송파구 백제고분로 12길 8-10 3층
                      </MPTypography>
                    </MPBox>
                    <MPBox>
                      <MPTypography color="black" variant="body2" fontWeight="regular">
                        (주)프라버던스
                      </MPTypography>
                    </MPBox>
                  </MPBox>
                  <MPBox mt={{ xs: 3, lg: 8 }} mx={{ xs: 2, lg: 4 }}>
                    <MPButton
                      component={Link}
                      to={"/inquiry"}
                      variant="contained"
                      size="medium"
                      color="info"
                      sx={{ fontSize: "initial" }}
                      fullWidth
                    >
                      문의하기
                    </MPButton>
                  </MPBox>
                </MPBox>
              </Grid>
            </Grid>
          </MPBox>
          <MPBox mt={4} display="flex" justifyContent="center"></MPBox>
        </MPBox>
      </MPBox>

      <SvcFooter />
    </HomeLayout>
  );
}

export default CompanyInfo;
