// ====================== React Library ===========================

import { useState, useEffect } from "react";
import { useMaterialUIController } from "context";
import { isAdmin, isLogIn } from "lib/api/utils";
import PropTypes from "prop-types";

// ====================== MP&MUI Components  ======================
import BusinessIcon from "@mui/icons-material/Business";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import SensorsOutlinedIcon from "@mui/icons-material/SensorsOutlined";
import TerminalOutlinedIcon from "@mui/icons-material/TerminalOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CloudIcon from "@mui/icons-material/Cloud";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Menu from "@mui/material/Menu";
import MPBox from "components/MPBox";

// ====================== Service Components ======================
import HomeNavbarLink from "services/Navbars/HomeNavbar/HomeNavbarLink";

// ====================== Images ==================================

function HomeNavbarLinkMobile({ open, close }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();

  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;

  const { width } = open && open.getBoundingClientRect();

  useEffect(() => {
    setNavbarType("sticky");
  }, [dispatch, fixedNavbar]);

  return (
    <Menu
      getcontentanchorel={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <MPBox px={0.5}>
        <HomeNavbarLink
          icon={<BusinessIcon />}
          name="DYB Soft 소개 및 서비스"
          route="/company-info"
          light={false}
        />
        <HomeNavbarLink
          icon={<CloudIcon />}
          name="AWS 솔루션 구축 사례"
          route="/awspage"
          light={false}
        />
        <HomeNavbarLink
          icon={<TerminalOutlinedIcon />}
          name="AWS, 소프트웨어 개발 문의"
          route="/inquiry"
          light={false}
        />
        <HomeNavbarLink
          icon={<SensorsOutlinedIcon />}
          name="아웃소싱 프로젝트 현황 (SI & SM)"
          route="/project"
          light={false}
        />
        {isLogIn ? (
          <HomeNavbarLink
            icon={<AccessibilityIcon />}
            name="나의 활동 내역"
            route="/mypage"
            light={false}
          />
        ) : (
          <HomeNavbarLink
            icon={<AccessibilityIcon />}
            name="DYB Soft 로그인"
            route="/authentication/sign-in"
            light={false}
          />
        )}
        {isAdmin ? (
          <HomeNavbarLink
            icon={<SupervisorAccountIcon />}
            name="프로젝트 관리 (관리자)"
            route="/adm/project"
            light={false}
          />
        ) : (
          ""
        )}
        {isLogIn ? (
          <HomeNavbarLink
            icon={<ExitToAppIcon />}
            name="로그아웃"
            route="/"
            light={false}
            logout={true}
          />
        ) : (
          ""
        )}
      </MPBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
HomeNavbarLinkMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default HomeNavbarLinkMobile;
